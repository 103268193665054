<template>
  <div class="convertersConfigEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('converterConfigs.edit') }}
        </h4>
        <hr class="m-0 mb-4">
        <label
          for="converterTypes"
          class="form-label"
        >{{ $t('converterConfigs.converterType') }}</label>
        <select
          id="converterTypes"
          v-model="converterConfig.converterType"
          class="form-control mb-4"
          disabled
        >
          <option
            :value="null"
            disabled
          >
            {{ 'Select a type' }}
          </option>
          <option
            v-for="(type, index) in converterTypeOptions"
            :key="`converterType-converterTypeOption-${ index }`"
            :value="type"
          >
            {{ type }}
          </option>
        </select>
        <label
          for="connectionParams"
          class="form-label"
        >{{ $t('converterConfigs.connectionParams') }}</label>
        <select
          id="connectionParams"
          v-model="connectionParam"
          class="form-control mb-4"
          :disabled="!converterConfig.converterType"
        >
          <option
            :value="null"
            disabled
          >
            {{ 'Select a Connection Param' }}
          </option>
          <option
            v-for="(param, index) in connectionParams"
            :key="`connectionParams-connectionParamsOption-${ index }`"
            :value="param"
          >
            {{ param.id +' '+ param.uri }}
          </option>
        </select>
        <label
          for="inputTypes"
          class="form-label"
        >{{ $t('converterConfigs.inputTypes') }}</label>
        <Multiselect
          id="inputTypes"
          v-model="selectedInputTypes"
          tag-placeholder="Not found"
          placeholder="Select Input Types"
          :options="inputTypesOptions"
          select-label=""
          :multiple="true"
          :taggable="true"
          :hide-selected="true"
          :searchable="false"
          :close-on-select="true"
          class="mb-4"
        />
        <label
          for="outputTypes"
          class="form-label"
        >{{ $t('converterConfigs.outputTypes') }}</label>
        <Multiselect
          id="outputTypes"
          v-model="selectedOutputTypes"
          tag-placeholder="Not found"
          placeholder="Select Output Types"
          :options="outputTypesOptions"
          select-label=""
          :multiple="true"
          :taggable="true"
          :hide-selected="true"
          :searchable="false"
          :close-on-select="true"
          class="mb-4"
        />
        <label
          for="visibleTypes"
          class="form-label"
        >{{ $t('converterConfigs.visibleTypes') }}</label>
        <Multiselect
          id="visibleTypes"
          v-model="selectedVisibleTypes"
          tag-placeholder="Not found"
          placeholder="Select Visible Types"
          :options="visibleTypesOptions"
          select-label=""
          :multiple="true"
          :taggable="true"
          :hide-selected="true"
          :searchable="false"
          :close-on-select="true"
          class="mb-4"
        />
      </div>
      <label
        for="converterActive"
        class="form-label"
      >{{ $t('converterConfigs.active') }}</label>
      <div class="form-check mb-5">
        <input
          id="converterActive"
          v-model="converterConfig.active"
          type="checkbox"
          class="form-check-input"
        >
      </div> 
      <button
        class="btn btn-primary float-right"
        @click.prevent="editConverterConfig()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('save') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
import { converterConfigsRulesMixin } from '@/mixins/converterConfigsRulesMixin.js';

export default {
  name: "ConverterConfigsEdit",
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    errorMixin,
    converterConfigsRulesMixin
  ],
  props: {
    converterConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      converterTypeOptions: [],
      inputTypesOptions: [],
      selectedInputTypes: [],
      outputTypesOptions: [],
      selectedOutputTypes: [],
      visibleTypesOptions: [],
      selectedVisibleTypes: [],
      connectionParam: null,
      connectionParams: []
    }
  },
  created () {
    this.getMetaData();
    let val = this.converterConfig.converterType;
    var param = this.converterConfigsRulesMixin_getValidConnectionType(val);
    this.getConnectionParams(param);
    this.inputTypesOptions = this.converterConfigsRulesMixin_getValidVisibleAndInputTypes(val);
    this.outputTypesOptions = this.converterConfigsRulesMixin_getValidOutputTypes(val);
    this.visibleTypesOptions = this.converterConfigsRulesMixin_getValidVisibleAndInputTypes(val);
    this.getMultiselectData();
  },
  methods: {
    getMetaData () {
      this.axios.get('/ConverterConfig/GetConverterConfigMeta')
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        let meta = response.data;
        this.converterTypeOptions = meta.converterTypeOptions;
      });
    },
    editConverterConfig () {
      this.setMultiselectData();
      this.axios.put('/ConverterConfig/UpdateConverterConfig', this.converterConfig)
        .then(() => {
          this.$snotify.success(this.$t('converterConfigs.editSuccessfully'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    getConnectionParams (type) {
      this.axios.get('/ConverterConfig/GetConnectionParams?connectionType=' + type)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.connectionParams = response.data;
        var id = this.converterConfig.connectionParamsId;
        this.connectionParam = this.connectionParams.find(x => x.id == id);
        if(!this.connectionParam) {
          this.connectionParam = null;
        }
      });
    },
    getMultiselectData () {
      this.selectedInputTypes = this.converterConfig.inputTypes.split(',');
      this.selectedOutputTypes = this.converterConfig.outputTypes.split(',');
      this.selectedVisibleTypes = this.converterConfig.visibleTypes.split(',');
    },
    setMultiselectData () {
      if(this.connectionParam) {
        this.converterConfig.connectionParamsId = this.connectionParam.id;
      }
      
      this.converterConfig.inputTypes = this.selectedInputTypes.join(',');
      this.converterConfig.outputTypes = this.selectedOutputTypes.join(',');
      this.converterConfig.visibleTypes = this.selectedVisibleTypes.join(',');
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
