<template>
  <div class="converterConfigParamsEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('converterConfigs.editParams') }}
        </h4>
        <hr class="m-0 mb-4">
        <label
          for="uri"
          class="form-label"
        >{{ "URI" }}</label>
        <textarea
          id="converterTypes"
          v-model="connectionParam.uri"
          rows="2"
          class="form-control mb-4"
        />
        <label
          for="serviceName"
          class="form-label"
        >{{ "Service Name" }}</label>
        <input
          id="serviceName"
          v-model="connectionParam.serviceName"
          class="form-control mb-4"
        >
        <label
          for="username"
          class="form-label"
        >{{ "Username" }}</label>
        <input
          id="username"
          v-model="connectionParam.userName"
          class="form-control mb-4"
        >
        <label
          for="password"
          class="form-label"
        >{{ "Password" }}</label>
        <input
          id="password"
          v-model="connectionParam.password"
          class="form-control mb-4"
        >
        <label
          for="apiKey"
          class="form-label"
        >{{ "API Key" }}</label>
        <input
          id="apiKey"
          v-model="connectionParam.apiKey"
          class="form-control mb-4"
        >
        <label
          for="comment"
          class="form-label"
        >{{ "Comment" }}</label>
        <textarea
          id="comment"
          v-model="connectionParam.comment"
          rows="3"
          class="form-control mb-4"
        />
        <label
          for="params"
          class="form-label"
        >{{ "Params" }}</label>
        <textarea
          id="params"
          v-model="connectionParam.params"
          rows="2"
          class="form-control mb-4"
        />
      </div>
      <button
        class="btn btn-primary float-right"
        @click.prevent="saveConnectionParams()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('save') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "ConverterConfigParamsEdit",
  mixins: [
    errorMixin
  ],
  props: {
    converterConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      connectionParam: {},
    }
  },
  created () {
    this.connectionParam = Object.assign({}, this.converterConfig.connectionParams);
  },
  methods: {
    saveConnectionParams () {
      this.axios.put('/ConverterConfig/UpdateConnectionParams', this.connectionParam)
        .then(() => {
          this.$snotify.success(this.$t('converterConfigs.editParamsSuccessfully'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
