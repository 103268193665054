export const converterConfigsRulesMixin = {
  methods: {
    converterConfigsRulesMixin_getValidConnectionType (converterType) {
      if (['SkidataTDIProxy', 'SkidataOCC'].includes(converterType)) {
        return 'SkidataTDIProxy';
      }
      if (['Media', 'SkidataWrapper'].includes(converterType)) {
        return 'Web,Ticketcorner';
      }
      if (['AxessWrapper'].includes(converterType)) {
        return 'Ticketcorner';
      }
      if (['SkilineTokenWrapper'].includes(converterType)) {
        return 'Web,1';
      }
      if (['SkidataBarcode'].includes(converterType)) {
        return 'Web,2,4';
      }
      if (['JokerCard',].includes(converterType)) {
        return 'Web'
      }

      return converterType;
    },
    converterConfigsRulesMixin_getValidVisibleAndInputTypes (converterType) {
      if (['SkidataTDIProxy', 'SkidataWrapper', 'SkidataOCC'].includes(converterType)) {
        return ['SKIDATA', 'OCC', 'CHIP_NUMBER'];
      }
      if ('SkidataBarcode' == converterType) {
        return ['BARCODE'];
      }
      if (['Axess', 'AxessWrapper'].includes(converterType)) {
        return ['OCC', 'PROJECT_POS_SERIAL', 'WTP', 'CHIP_NUMBER', 'BARCODE', 'WTP_WIFI', 'POS_SERIAL'];
      } 
      if ('DSS' == converterType) {
        return ['BARCODE', 'POS_SERIAL', 'WTP', 'CHIP_NUMBER', 'PROJECT_POS_SERIAL', 'OCC'];
      }
      if ('Feratel' == converterType) {
        return ['BARCODE', 'FERATEL'];
      }
      if ('Generic' == converterType) {
        return ['CHIP_NUMBER', 'BARCODE', 'GENERIC_NUMBER', 'GENERIC'];
      }
      if ('JokerCard' == converterType) {
        return ['SKIDATA', 'GENERIC_NUMBER', 'GENERIC'];
      }
      if ('Media' == converterType) {
        return ['SKIDATA', 'FERATEL', 'BARCODE', 'CHIP_NUMBER', 'SKILINE_TOKEN', 'PROJECT_POS_SERIAL', 'GENERIC', 'GENERIC_NUMBER'];
      }
      if ('SkilineTokenWrapper' == converterType) {
        return ['SKILINE_TOKEN', 'CHIP_NUMBER'];
      }
      if ('Swisspass' == converterType) {
        return ['SWISSPASS'];
      }
      if ('Ticketcorner' == converterType) {
        return ['TICKETCORNER'];
      }
      return [];
    },
    converterConfigsRulesMixin_getValidOutputTypes (converterType) {
      if (['SkidataTDIProxy', 'SkidataWrapper', 'SkidataBarcode'].includes(converterType)) {
        return ['SKIDATA'];
      }
      if ('SkidataOCC' == converterType) {
        return ['OCC'];
      }
      if (['Axess', 'AxessWrapper', 'DSS'].includes(converterType)) {
        return ['PROJECT_POS_SERIAL', 'WTP', 'POS_SERIAL'];
      }
      if ('Feratel' == converterType) {
        return ['FERATEL'];
      }
      if (['Generic', 'JokerCard'].includes(converterType)) {
        return ['GENERIC_NUMBER'];
      }
      if ('Media' == converterType) {
        return ['CHIP_NUMBER', 'BARCODE', 'SKIDATA', 'PROJECT_POS_SERIAL', 'FERATEL'];
      }
      if ('SkilineTokenWrapper' == converterType) {
        return ['SKILINE_TOKEN', 'CHIP_NUMBER'];
      }
      if (['Swisspass', 'Ticketcorner'].includes(converterType)) {
        return ['CHIP_NUMBER'];
      }
      return [];
    }
  }
}