<template>
  <div class="converterConfigsList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="converterConfigs">
        <Grid
          :ref="kgm_ref"
          :style="{height: 'auto'}"
          :data-items="kgm_computedGridItems(converterConfigs)"
          :columns="kgm_responsiveColumns()"
          :filterable="true"
          :filter="kgm_filter"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(converterConfigs)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode
          }"
          :sort="kgm_sort"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <div
            slot="converterTypeFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="converterTypeFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="Filter by ConverterType"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('converterTypeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <template
            slot="activeTemplate"
            slot-scope="{props}"
          >
            <td>
              <font-awesome-icon
                v-if="!props.dataItem.active"
                class="red"
                icon="times"
              />
              <font-awesome-icon
                v-else
                class="green"
                icon="check"
              />
            </td>
          </template>
          <template
            slot="optionsTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <div
                class="btn-group"
                style="width: 100%"
              >
                <button
                  class="btn btn-primary btn-sm col-6"
                  @click="showSidebarEdit(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  <span>Edit</span>
                </button>
                <button
                  class="btn btn-danger btn-sm col-6"
                  @click="openConfirmModal(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="trash"
                  />
                  <span>Remove</span>
                </button>
              </div>
              <div
                v-if="isFeratel(props.dataItem)"
                class="btn-group mt-1"
                style="width: 100%"
              >
                <button
                  class="btn btn-primary btn-sm col-12"
                  @click="showSidebarParamsEdit(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  <span>Edit Connection Params</span>
                </button>
              </div>
            </td>
          </template>
        </Grid>
        <Sidebar
          v-if="showSidebar"
          :show-sidebar="showSidebar"
          @close="close"
        >
          <ConverterConfigParamsEdit
            v-if="editParams"
            :converter-config="converterConfig"
            @reload="reloadConverterConfigs"
          />
          <ConverterConfigsAdd
            v-else-if="addingElement"
            :resort-id="resortId"
            @reload="reloadConverterConfigs"
          />
          <ConverterConfigsEdit
            v-else
            :converter-config="converterConfig"
            @reload="reloadConverterConfigs"
          />
        </Sidebar>
      </template>

      <SweetModal
        ref="confirmDelete"
        :title="$t('introOutroListComp.deleteElement')"
        icon="warning"
        blocking
        class="overflowHidden"
      >
        <p v-if="converterConfig">
          {{ $t('sureToDelete', {'0' : converterConfig.converterType}) }}
        </p>
        <button
          slot="button"
          class="btn btn-secondary float-left mb-3"
          @click="$refs.confirmDelete.close()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="times"
          />{{ $t('cancel') }}
        </button>
        <button
          slot="button"
          class="btn btn-danger float-right mb-3"
          @click="removeConverterConfig()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="trash"
          />{{ $t('delete') }}
        </button>
        <div class="clearfix" />
      </SweetModal>
    </template>
  </div>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import ConverterConfigsAdd from '@/components/ConverterConfigs/ConverterConfigsAdd';
import ConverterConfigsEdit from '@/components/ConverterConfigs/ConverterConfigsEdit';
import ConverterConfigParamsEdit from '@/components/ConverterConfigs/ConverterConfigParamsEdit';

export default {
  name: "ConverterConfigs",
  components: {
    SweetModal,
    Sidebar,
    ConverterConfigsAdd,
    ConverterConfigsEdit,
    ConverterConfigParamsEdit
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true,
      default () {
        return -1;
      }
    }
  },
  data () {
    return {
      loading: true,
      resorts: null,
      converterConfig: null,
      converterConfigs: null,
      showSidebar: false,
      addingElement: false,
      editParams: false,
      currentId: null,
      deleteIndex: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'id',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "id",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'id',
          filterable: false,
          filter: 'text',
          title: 'Id',
          width: '60px',
        },
        {
          field: 'converterType',
          filterable: true,
          filter: 'text',
          title: 'Converter Type',
          filterCell: 'converterTypeFilter',
          width: '160px'
        },
        {
          field: 'connectionParamsId',
          filterable: false,
          filter: 'text',
          title: 'Connection ParamsId',
          width: '70px',
          hideOn: ['mdDown']
        },
        {
          field: 'inputTypes',
          filterable: false,
          filter: 'text',
          title: 'Input Types',
          hideOn: ['xsDown']
        },
        {
          field: 'outputTypes',
          filterable: false,
          filter: 'text',
          title: 'Output Types',
          hideOn: ['xsDown']
        },
        {
          field: 'active',
          filterable: false,
          filter: 'text',
          title: 'Active',
          type: 'checkbox',
          cell: 'activeTemplate',
          width: '65px',
          hideOn: ['mdDown']
        },
        {
          field: 'visibleTypes',
          filterable: false,
          filter: 'text',
          title: 'Visible Types',
          hideOn: ['xsDown']
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '200px'
        }
      ]
    }
  },
  computed: {
    isConverterType () {
      return this.resort && this.resort.connectorType == "CONVERTER";
    }
  },
  metaInfo () {
    return {
      title: this.$t('menu.converterConfigs')
    }
  },
  watch: {
    resortId () {
      this.loadConverterConfigs();
    }
  },
  created () {
    this.loadConverterConfigs();
  },
  methods: {
    isFeratel (val) {
      return val.converterType == "Feratel";
    },
    //sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.editParams = false;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentConfig(emitValues);
      this.addingElement = false;
      this.editParams = false;
      this.show();
    },
    showSidebarParamsEdit (emitValues) {
      this.setCurrentConfig(emitValues);
      this.editParams = true;
      this.addingElement = false;
      this.show();
    },
    setCurrentConfig (config) {
      this.converterConfig = Object.assign({}, config); 
    },
    //end sidebar

    //api
    loadConverterConfigs () {
      this.loading = true;
      this.getConverterConfigs();
    },
    reloadConverterConfigs () {
      this.loading = true;
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getConverterConfigs();
      this.reloadResortCache()
    },
    openConfirmModal (data) {
      this.setCurrentConfig(data);
      this.deleteIndex = data.id;
      this.$refs.confirmDelete.open();
    },
    removeConverterConfig () {
      this.axios.delete(`/ConverterConfig/DeleteConverterConfig?id=${ this.deleteIndex }`)
      .then(() => {
        this.$snotify.success(this.$t('applicationSettingsComponent.deletedSuccessfully'));
        this.$refs.confirmDelete.close();
        this.reloadConverterConfigs(this.resortId);
      })
      .finally(() => {
        this.deleteIndex = null;
      });
    },
    getConverterConfigs () {
      this.axios.get('/ConverterConfig/GetAllConverterConfigs?resortId='+this.resortId)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.converterConfigs = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    reloadResortCache () {
      this.axios.post(`/Resort/ReloadResortCache`)
      .then(() => {
      })
      .catch(() => {
        this.$snotify.success('Reload Resort Cache not successfull - changes are not yet applied to the system!');
      });
    },
  }
}
</script>
<style>
.applicationSettingsList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}

.converterConfigsList .k-grid table .k-link {
  white-space: pre-line;
}

</style>